import {
  Z_Money,
  Z_UserUploadedDocument,
  Z_TenantLink,
  Z_HoldingLink,
  Z_ConsumerLink,
  Z_WarrantorLink,
  Z_Warranty,
  Z_WarrantyLink,
} from '@rabbit/data/types';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import { z } from 'zod';
import { ICF_Base } from '../base';
import { APICallParamsBase } from '@rabbit/elements/shared-types';

export type OliveRegisterConsumerHolding_Payload = Omit<
  z.infer<typeof ICF_OliveRegisterConsumerHolding>,
  keyof APICallParamsBase
>;

const Z_HoldingRegistrationFormData = z.object({
  purchase_time: z.number(),
  purchase_price: Z_Money.optional(),
  purchase_location: z.string().nullable().optional(),
  purchase_location_other: z.string().nullable().optional(),
  purchase_country: z.string().optional(),
  serial: z.string().optional(),
  receipt: z.array(Z_UserUploadedDocument).optional(),
});

const Z_OliveHoldingRegistrationFormData = z.object({
  purchase_time: z.number(),
  purchase_price: Z_Money.optional(),
  purchase_location: z.nullable(z.string()).optional(),
  purchase_location_other: z.nullable(z.string()).optional(),
  purchase_country: z.string().optional(),
  serial: z.string().optional(),
  receipt: z.array(Z_UserUploadedDocument).optional(),
});

export const ICF_OliveRegisterConsumerHolding = z
  .object({
    vendableDocid: z.string(),
    formData: Z_HoldingRegistrationFormData,
  })
  .merge(ICF_Base);

export const OCF_OliveRegisterConsumerHolding = z.string();

export const CFC_OliveRegisterConsumerHolding = MakeCloudFunctionSpec(
  'api/legacy/cfcoliveregisterconsumerholding',
  ICF_OliveRegisterConsumerHolding,
  OCF_OliveRegisterConsumerHolding
);

export type SageRegisterConsumerHolding_Payload = Omit<
  z.infer<typeof ICF_SageRegisterConsumerHolding>,
  keyof APICallParamsBase
>;

export const ICF_SageRegisterConsumerHolding = z
  .object({
    tenantLink: Z_TenantLink, // the active tenant
    // Optional params
    holdingLink: z.optional(Z_HoldingLink).nullable(),
    consumerLink: z.optional(Z_ConsumerLink).nullable(),
    warrantorLink: z.optional(Z_WarrantorLink).nullable(),
    formData: z.record(z.any()).optional(),
    newHoldingLink: z.optional(Z_HoldingLink).nullable(), //for holdingLink generated at FE
    newConsumerLink: z.optional(Z_ConsumerLink).nullable(), //for holdingLink generated at FE
  })
  .merge(ICF_Base);

export const OCF_SageRegisterConsumerHolding = z.object({
  warranty: Z_WarrantyLink,
  holdingLink: Z_HoldingLink,
  consumerLink: Z_ConsumerLink,
});

export const CFC_SageRegisterConsumerHolding = MakeCloudFunctionSpec(
  'api/legacy/cfcsageregisterconsumerholding',
  ICF_SageRegisterConsumerHolding,
  OCF_SageRegisterConsumerHolding
);
