import LabelledItem, {
  LabelledItemProps,
} from '../../atoms/LabelledItem/LabelledItem';

export interface LabelledItemShape {
  label: string;
  value?: string | JSX.Element;
  key?: string;
}
export interface LabelledItemListProps {
  items: LabelledItemShape[];
  itemProps?: LabelledItemProps;
  listWrapperClasses?: string;
}

export function LabelledItemList({
  items,
  itemProps,
  listWrapperClasses = 'font-nunito grid grid-cols-5 gap-y-6 gap-x-4',
}: LabelledItemListProps) {
  const { wrapperClasses, labelClasses, valueClasses } = itemProps || {};

  return (
    <div className={listWrapperClasses}>
      {items.map((item) => (
        <LabelledItem
          key={item.key || item.label}
          label={item.label}
          value={item.value}
          wrapperClasses={wrapperClasses}
          labelClasses={labelClasses}
          valueClasses={valueClasses}
        />
      ))}
    </div>
  );
}
export default LabelledItemList;
