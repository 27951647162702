import { SetStateAction, useEffect, useState } from 'react';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { DTVendable } from '@rabbit/data/types';
import { SearchVendablesByDocid } from '@rabbit/search/cherchons';
import { useLocation } from 'react-router-dom';
import { formatAndGetLastCategory } from '../utils/helpers';
import { useTranslation } from 'react-i18next';
import { ShieldCheckIcon } from '@heroicons/react/24/solid';
import { ModalAddEditVendable } from '../components/organisms/ModalNewEditProduct/ModalTypes/ModalAddEditVendable';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VendableDetailsViewProps {}

export function VendableDetailsView(props: VendableDetailsViewProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const vendableDocid = location.pathname.split('/')[2];
  const [vendableData, setVendableData] = useState<DTVendable>();
  
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);

  const getProductDetails = async (docid: string) => {
    const facetFilters = [];
    facetFilters.push({
      name: 'mfr',
      value: `M:${t('tenantLink')}`,
    });

    const options = {
      hitsPerPage: 25,
      page: 0,
      facetFilters,
    };
    const data = await SearchVendablesByDocid(docid, options);

    setVendableData(data.items[0]);
  };

  useEffect(() => {
    getProductDetails(vendableDocid).catch((err) => console.log(err));
  }, [vendableDocid]);

  if (!vendableData) return <LoadingSpinner size="md" extraClasses="mt-16" />;

  return (
    <div className="flex flex-col gap-5">
      <div className="flex w-full items-start justify-between gap-4 bg-gray-100 rounded-md p-4">
        <div className="flex w-1/3 grow items-start gap-3">
          <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
            <img src={vendableData?.img?.[0] ?? ''} alt="productImg" className="w-20 h-20 rounded-lg" />
          </div>
          <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
            <p className="w-full overflow-hidden text-ellipsis text-xl font-caudex">
              {vendableData?.full}
            </p>
            <p>
              {vendableData?.category && formatAndGetLastCategory(vendableData?.category)}
            </p>
          </div>
        </div>
        <div className="flex w-1/3 grow items-start gap-3">
          <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
            <ShieldCheckIcon className="h-8 w-8 text-gray-500" />
          </div>
          <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
            <p className="w-full overflow-hidden text-ellipsis text-xl">
              1 year warranty
            </p>
            <p className="text-base opacity-50 text-ellipsis overflow-hidden w-full">
              Shade - 1 year
            </p>
          </div>
        </div>
      </div>
      {/* <div className="border border-gray-200 rounded-lg p-2 flex flex-col gap-4">
        <div className="flex gap-2">
          <div className="flex justify-start gap-4 grow">
            <div>
              <img src={mockImage1} alt="productImg" className="w-20 h-20 rounded-lg" />
            </div>
            <div className="flex flex-col gap-4 py-4">
              <div className="flex gap-2 flex-col">
                <div className="text-base">{vendableData.full}</div>
                <div className="flex gap-1">
                  <div className="bg-green-100 py-1 px-2 rounded overflow-hidden inline green-100">Imported</div> 
                  <div className="bg-red-100 py-1 px-2 rounded overflow-hidden inline">Hidden</div>
                  </div>
              </div>
            </div>
          </div>
          <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
            <p className="w-full overflow-hidden text-ellipsis text-xl font-caudex">
              {vendableData.full}
            </p>
            <p>
              {vendableData.category && formatAndGetLastCategory(vendableData.category)}
            </p>
          </div>
        </div>
        <div className="flex w-1/3 grow items-start gap-3">
          <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
            <ShieldCheckIcon className="h-8 w-8 text-gray-500" />
          </div>
          <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
            <p className="w-full overflow-hidden text-ellipsis text-xl">
              1 year warranty
            </p>
            <p className="text-base opacity-50 text-ellipsis overflow-hidden w-full">
              Shade - 1 year
            </p>
          </div>
        </div>
      </div>
      {/* <div className="border border-gray-200 rounded-lg p-2 flex flex-col gap-4">
        <div className="flex gap-2">
          <div className="flex justify-start gap-4 grow">
            <div>
              <img src={mockImage1} alt="productImg" className="w-20 h-20 rounded-lg" />
            </div>
            <div className="flex flex-col gap-4 py-4">
              <div className="flex gap-2 flex-col">
                <div className="text-base">{vendableData.full}</div>
                <div className="flex gap-1">
                  <div className="bg-green-100 py-1 px-2 rounded overflow-hidden inline green-100">Imported</div> 
                  <div className="bg-red-100 py-1 px-2 rounded overflow-hidden inline">Hidden</div>
                  </div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <ButtonIcon
                kind="bgLightGreen"
                onClick={() => console.log('clicked syncronize')}
                label="Syncronize"
                Icon={ArrowPathIcon}
                iconLeft
              />
          </div>
        </div>
        <div className="w-full flex gap-2 bg-gray-100 border border-gray-200 px-3 py-2 rounded-lg items-center">
          <div className="grow">Show on search</div>
          <Toggle name="search" checked={false} onChange={function (): void {
            throw new Error('Function not implemented.');
          } } />
        </div>
      </div> */}
      <CardWrapperWithHeader
        title={'Product information'}
        headerRight={
          <ButtonIcon
            iconLeft={true}
            label={'Edit product'}
            kind={'bgLightGreen'}
            onClick={() => setIsEditProductModalOpen(true)}
            //onClick={() => console.log('clicked edit product')}
            Icon={ArrowTopRightOnSquareIcon}
          />
        }
      >
        {isEditProductModalOpen && (
          <Modal 
            kind={'generic'} 
            className='bg-white max-w-[90vw] md:w-3/4 lg:w-2/4 rounded-md h-fit'
            settings={{
              handleClose: () => setIsEditProductModalOpen(false),
              title: 'Edit product'
            }}
          >
            <ModalAddEditVendable
              vendableId={vendableDocid}
            />
          </Modal>
        )}
        <div className="grid grid-cols-6 gap-10">
          {renderData('Status', <div className="bg-gray-100 py-1 px-2 rounded overflow-hidden inline">Unpublished</div>)}
          {renderData('Product name', `${vendableData.full}`)}
          {renderData('Brand', `${vendableData.brand}`)}
          {renderData(
            'Category',
            `${formatAndGetLastCategory(vendableData.category)}`
          )}
          {renderData('SKU', `${vendableData.mpn}`)}
          {renderData('UPC', `${vendableData.upc}`)}
          {renderData('Warranty plan', `${vendableData.defaultTemplate}`)}
          {renderData('Warranty term', `${vendableData.defaultTemplate}`)}
          {renderData('Price', `${vendableData.defaultTemplate}`)}
          {renderData('Deprecated', `${vendableData.deprecated ? 'Yes' : 'No'}`)}
          {renderData('Search keywords', `-`)}
          
            <div className="flex flex-col">
              <p className="mb-[10px] text-sm text-gray-500">User Manual</p>
              {vendableData?.manual?.[0]? (
              <a
                href={vendableData.manual[0]}
                className="font-nunito text-base underline"
              >
                Link
              </a>
              ) : <div className="overflow-hidden text-ellipsis font-medium">-</div>}
            </div>
        </div>
      </CardWrapperWithHeader>
    </div>
  );
}

export default VendableDetailsView;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

/* ----------------------------- Render helpers ----------------------------- */

const renderData = (label: string, value: any) => {
  let renderValue = value;
  if (value === 'undefined') renderValue = '-';
  else if (value && value !== 'undefined' && label === 'Phone number')
    renderValue = `+${value}`;
  return (
    <div>
      <p className="mb-[10px] text-sm text-gray-500">{label}</p>
      <div className="overflow-hidden text-ellipsis font-medium">
        {renderValue ?? '-'}
      </div>
    </div>
  );
};
