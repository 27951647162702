import { useField, useFormikContext } from 'formik';
import { InputTypeCascadeSettingsShape } from '@rabbit/elements/shared-types';
import DropdownCascade from 'react-dropdown-cascade';
import { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

export interface InputTypeCascadeProps {
  name: string;
  settings: InputTypeCascadeSettingsShape;
}

export function InputTypeCascade({ name, settings, form }: any) {
  const { setFieldValue } = useFormikContext();
  const {
    options,
    placeholder,
    isMulti = false,
    isClearable = true,
    disabled = false,
    onChange: defaultOnChange,
    ...props
  } = settings;
  const [field, meta] = useField(name);
  const [dropdownValue, setDropdownValue] = useState<string | undefined>(undefined);

  let errorField = false;
  if (meta?.error) errorField = true;

  const clearValue = () => {
    setDropdownValue(undefined);
    setFieldValue(name, undefined);
  };

  const renderInput = (props: any) => {
    return <div className="relative"><input 
        {...props} 
        placeholder={placeholder}
        className='border rounded-lg border-gray-300 black bg-gray-50 font-nunito text-base text-gray-900 w-full py-3 px-2 !outline-none'
      />
      {dropdownValue && <div onClick={clearValue} className="absolute right-[5px] top-[12px] z-10 w-[20px] h-[20px] cursor-pointer"><XMarkIcon/></div>}
      </div>
  };

  return (
    <div>
    <DropdownCascade
      customInput={renderInput}
      customStyles={{
        dropdown: {
          className: 'w-full',
        },
        dropdownMenu: {
          className: '!p-0 bg-gray-100 border border-gray-200 !animate-none',
        },
        dropdownMenuItem: {
          className: 'font-nunito text-base text-gray-900 bg-gray-100',
        },
        dropdownSubitem: {
          className: 'font-nunito text-base text-gray-900 bg-gray-100',
        },
      }}
      items={options}
      onSelect={(value, selectedItems) => {
        //console.log(value, selectedItems);
        setDropdownValue(value);
        setFieldValue(name, value);
      }}
      value={dropdownValue}
     />
    </div>
  );
}

export default InputTypeCascade;
