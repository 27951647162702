import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import {
  Button,
  CardWrapperWithHeader,
  Modal,
  formatUnixTime,
  getCurrencyFormat,
  getFormattedDate,
  getWarrantyTerm,
} from '@rabbit/elements/shared-components';
import {
  formatAndGetLastCategory,
  getExpiryDate,
  getVehicleCategoryByValue,
} from '../../../../utils/helpers';
import {
  AppInfoShape,
  DTConsumer_Private,
  DTHoldingProxy,
  DTHolding_Manufacturer,
  DTHolding_Private,
  DTHolding_Public,
  TableCover,
  UserUploadedDocument,
  VehicleInfo,
  Warranty,
} from '@rabbit/data/types';
import { SetStateAction, useState, useContext } from 'react';
import { useConsumerHoldingEditor } from '@rabbit/bizproc/react';
import Skeleton from 'react-loading-skeleton';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { SageFileUploader } from '@rabbit/sage/components/organisms/upload-wrapper/SageFileUploader';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import { useTranslation } from 'react-i18next';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { ConfigContext } from '@rabbit/config/context';

interface RegistrationsHoldingDetailsSectionProps {
  allHoldingsList?: DTHoldingProxy[] | null;
  consumer_private?: DTConsumer_Private | null;
  holding_proxy?: DTHoldingProxy | null;
  holdingId: string;
  storeAddress: string;
}
export default function RegistrationsHoldingDetailsSection({
  allHoldingsList,
  holding_proxy,
  consumer_private,
  holdingId,
  storeAddress,
}: RegistrationsHoldingDetailsSectionProps) {
  const appInfo = useAppInfo();
  const [proofOfPurchaseModal, setProofOfPurchaseModal] =
    useState<boolean>(false);
  const [proofOfSerialNumberModal, setProofOfSerialNumberModal] =
    useState<boolean>(false);

  // Ideally this hook wouldn't be necessary, but it currently is used to provide real time updates when a proof of purchase is updated
  // Will remove later - DC
  const { body } = useConsumerHoldingEditor(
    consumer_private?.docid || '',
    holdingId.split('_')[0]
  );
  const { t } = useTranslation();

  const warranty = BL_Warranty.getLatestWarranty(holding_proxy?.warranties);

  const renderDetails = () => {
    if (holding_proxy && allHoldingsList) {
      switch (t('tenantLink')) {
        case 'WARRANTYIRELAND':
          return renderWarrantyIrelandDetails(
            holding_proxy,
            appInfo,
            setProofOfPurchaseModal,
            body,
            holdingId,
            allHoldingsList
          );
        case 'NUCOVER':
          return renderNuCoverDetails(
            holding_proxy,
            setProofOfPurchaseModal,
            body,
            holdingId,
            allHoldingsList,
            appInfo,
            storeAddress
          );
        case 'PINNACLEWARRANTIES':
          return renderPinnacleWarrantiesDetails(
            holding_proxy,
            appInfo,
            setProofOfPurchaseModal,
            body,
            holdingId,
            allHoldingsList
          );
        default:
          return renderDefaultDetails(
            holding_proxy,
            storeAddress,
            setProofOfPurchaseModal,
            setProofOfSerialNumberModal,
            body,
            holdingId,
            allHoldingsList,
            warranty
          );
      }
    }
  };

  return (
    <>
      <CardWrapperWithHeader title={t('Purchase details')}>
        {!holding_proxy || !allHoldingsList ? (
          <div>
            <div className="font-nunito mb-[30px] grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
            </div>
            <div className="font-nunito grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
            </div>

            <div className="font-nunito grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
            </div>
          </div>
        ) : (
          <div className="font-nunito grid grid-cols-6 gap-x-4 gap-y-6">
            {renderDetails()}
          </div>
        )}
      </CardWrapperWithHeader>
      {proofOfPurchaseModal && (
        <Modal
          kind="generic"
          settings={{
            title: 'Proof of purchase',
            handleClose: () => setProofOfPurchaseModal(false),
          }}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <SageFileUploader
              label="Proof of purchase"
              identifiers={{
                category: UploadedFileCategories.ConsumerProofPurchase,
                docType: {
                  docid: holdingId.split('_')[0],
                  type: DocTypeShapeTypes.Case,
                },
                personaId: consumer_private?.docid ?? '',
              }}
              //alterCaseFacts={alterCaseFacts} //No need - it's working without it
              accepts={['image/*', '.pdf']}
              //onUploadCompleted={onProofOfPurchaseUpdated} //No need - it's working without it
              currentFiles={body?.private?.receipt ?? []}
              shouldAutoUpdateDocs={true}
            />
            <div className="mt-4 flex w-full gap-2">
              <Button
                kind="primary"
                onClick={() => setProofOfPurchaseModal(false)}
              >
                {t('OK')}
              </Button>
              <Button kind="red" onClick={() => setProofOfPurchaseModal(false)}>
                {t('Close')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {proofOfSerialNumberModal && (
        <Modal
          kind="generic"
          settings={{
            title: t('Proof of serial number'),
            handleClose: () => setProofOfSerialNumberModal(false),
          }}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <SageFileUploader
              label={t('Proof of serial number')}
              identifiers={{
                category: UploadedFileCategories.SerialNumberProof,
                docType: {
                  docid: holdingId.split('_')[0],
                  type: DocTypeShapeTypes.Case,
                },
                personaId: consumer_private?.docid ?? '',
              }}
              accepts={['image/*', '.pdf']}
              currentFiles={body?.private?.serial_proof ?? []}
              shouldAutoUpdateDocs={true}
            />
            <div className="mt-4 flex w-full gap-2">
              <Button
                kind="primary"
                onClick={() => setProofOfSerialNumberModal(false)}
              >
                {t('OK')}
              </Button>
              <Button
                kind="red"
                onClick={() => setProofOfSerialNumberModal(false)}
              >
                {t('Close')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

/* ----------------------------- Render helpers ----------------------------- */

const renderDefaultDetails = (
  holding_proxy: DTHoldingProxy,
  storeAddress: string,
  setProofOfPurchaseModal: {
    (value: SetStateAction<boolean>): void;
    (value: SetStateAction<boolean>): void;
  },
  setProofOfSerialNumberModal: (value: SetStateAction<boolean>) => void,
  body: {
    public: DTHolding_Public | null;
    private: DTHolding_Private | null;
    manufacturer: DTHolding_Manufacturer | null;
  },
  holdingId: string,
  allHoldingsList: DTHoldingProxy[],
  warranty?: Warranty
) => {
  const { config } = useContext(ConfigContext) || {};
  return (
    <>
      {renderData('Product name', holding_proxy.title)}
      {renderData(
        'Model number',
        holding_proxy.mpn && holding_proxy.mpn !== 'n/a'
          ? holding_proxy.mpn
          : '-'
      )}
      {renderData(
        'Serial number',
        holding_proxy?.serial === '' ? '-' : holding_proxy?.serial
      )}
      {renderData('Store', storeAddress || '-')}
      {Array.isArray(holding_proxy.category) &&
        renderData(
          'Product category',
          formatAndGetLastCategory(holding_proxy.category)
        )}
      {renderData(
        'Warranty ID',
        warranty?.warrantyLink?.slice(0, 5).toUpperCase()
      )}
      {holding_proxy.purchase_time &&
        renderData(
          'Purchase date',
          holding_proxy.purchase_time && holding_proxy.purchase_time > 0
            ? formatUnixTime(holding_proxy.purchase_time, 'dd/MM/yyyy')
            : '-'
        )}
      {renderData(
        'Registration date',
        holding_proxy.register_time && holding_proxy.register_time > 0
          ? formatUnixTime(holding_proxy.register_time, 'dd/MM/yyyy')
          : '-'
      )}
      {renderData(
        'Proof of purchase',
        renderPoP(setProofOfPurchaseModal, body?.private?.receipt)
      )}
      {config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF &&
        renderData(
          'Proof of serial number',
          renderPoP(setProofOfSerialNumberModal, body?.private?.serial_proof)
        )}
      {renderData(
        'Expiry date',
        BL_Warranty.getLatestWarranty(holding_proxy?.warranties)?.endDate
          ? formatUnixTime(
              BL_Warranty.getLatestWarranty(holding_proxy?.warranties)?.endDate,
              'dd/MM/yyyy'
            )
          : '-'
      )}
      {holding_proxy.purchase_price &&
        renderData(
          'Purchase price',
          holding_proxy.purchase_price.amount !== 0 &&
            holding_proxy.purchase_price.currency !== '-'
            ? getCurrencyFormat(
                holding_proxy.purchase_price.amount,
                holding_proxy.purchase_price.currency
              )
            : '-'
        )}
      {warranty && renderData('Warranty term', getWarrantyTerm(holding_proxy))}
      {renderData(
        'Warranty expired',
        holding_proxy?.purchase_time && holding_proxy?.purchase_time > 0
          ? (getExpiryDate('number', holdingId, allHoldingsList) as number) *
              1000 <
            new Date().getTime()
            ? 'Yes'
            : 'No'
          : '-'
      )}
    </>
  );
};

const renderWarrantyIrelandDetails = (
  holding_proxy: DTHoldingProxy,
  appInfo: AppInfoShape,
  setProofOfPurchaseModal: {
    (value: SetStateAction<boolean>): void;
    (value: SetStateAction<boolean>): void;
  },
  body: {
    public: DTHolding_Public | null;
    private: DTHolding_Private | null;
    manufacturer: DTHolding_Manufacturer | null;
  },
  holdingId: string,
  allHoldingsList: DTHoldingProxy[]
) => {
  const productInfo = holding_proxy.self_registration?.srvInfo
    ?.productInfo as VehicleInfo;
  const { t } = useTranslation();

  return (
    <>
      {renderData('Make', holding_proxy.self_registration?.brand)}
      {renderData('Model', productInfo?.model)}
      {renderData(
        'Current Mileage',
        productInfo?.mileage &&
          productInfo?.mileage + '' + productInfo?.mileageUnit
      )}
      {renderData('VIN', productInfo?.chassisNo)}
      {renderData('Store', appInfo.name)}
      {holding_proxy.self_registration?.srvInfo?.type &&
        renderData(
          'Product category',
          holding_proxy.self_registration?.srvInfo?.type
            ?.charAt(0)
            .toUpperCase() +
            holding_proxy.self_registration?.srvInfo?.type?.slice(1)
        )}
      {renderData(
        'Purchase date',
        holding_proxy?.purchase_time && holding_proxy?.purchase_time > 0
          ? getFormattedDate(holding_proxy.purchase_time)
          : '-'
      )}
      {renderData(
        'First registration date',
        productInfo?.regDate ? getFormattedDate(productInfo?.regDate) : '-'
      )}
      {renderData(
        'Year of manufacture',
        productInfo?.yearOfManufacture ? productInfo?.yearOfManufacture : '-'
      )}
      {renderData(
        'NCT date',
        productInfo?.techCheckDate &&
          productInfo?.techCheckDate !== '0000-00-00'
          ? productInfo?.techCheckDate
          : '-'
      )}
      {/* {renderData(
        'Proof of purchase',
        renderPoP(setProofOfPurchaseModal, body?.private?.receipt)
      )} */}
      {holding_proxy.purchase_price &&
        renderData(
          'Purchase price',
          holding_proxy.purchase_price.amount !== 0 &&
            holding_proxy.purchase_price.currency !== '-'
            ? getCurrencyFormat(
                holding_proxy.purchase_price.amount,
                holding_proxy.purchase_price.currency
              )
            : '-'
        )}
      {renderData(
        'Registration Type',
        productInfo?.isManuallyRegistered ? t('Manual') : t('VRM')
      )}
    </>
  );
};

const renderNuCoverDetails = (
  holding_proxy: DTHoldingProxy,
  setProofOfPurchaseModal: {
    (value: SetStateAction<boolean>): void;
    (value: SetStateAction<boolean>): void;
  },
  body: {
    public: DTHolding_Public | null;
    private: DTHolding_Private | null;
    manufacturer: DTHolding_Manufacturer | null;
  },
  holdingId: string,
  allHoldingsList: DTHoldingProxy[],
  appInfo: any,
  storeAddress: string
) => {
  const store = holding_proxy.self_registration?.brand || storeAddress;
  return (
    <>
      {renderData(
        'Product name',
        (holding_proxy.self_registration?.srvInfo?.productInfo as TableCover)
          ?.typeofStone
      )}
      {renderData('Store', store || appInfo.name)}
      {renderData('Product category', 'Stones')}
      {renderData(
        'Purchase date',
        holding_proxy.purchase_time && holding_proxy.purchase_time > 0
          ? formatUnixTime(holding_proxy.purchase_time, 'dd/MM/yyyy')
          : '-'
      )}
      {renderData(
        'Registration date',
        holding_proxy.register_time && holding_proxy.register_time > 0
          ? formatUnixTime(holding_proxy.register_time, 'dd/MM/yyyy')
          : '-'
      )}
      {renderData(
        'Proof of purchase',
        renderPoP(setProofOfPurchaseModal, body?.private?.receipt)
      )}
    </>
  );
};

const renderPinnacleWarrantiesDetails = (
  holding_proxy: DTHoldingProxy,
  appInfo: AppInfoShape,
  setProofOfPurchaseModal: {
    (value: SetStateAction<boolean>): void;
    (value: SetStateAction<boolean>): void;
  },
  body: {
    public: DTHolding_Public | null;
    private: DTHolding_Private | null;
    manufacturer: DTHolding_Manufacturer | null;
  },
  holdingId: string,
  allHoldingsList: DTHoldingProxy[]
) => {
  const productInfo = holding_proxy.self_registration?.srvInfo
    ?.productInfo as VehicleInfo;
  const { t } = useTranslation();

  return (
    <>
      {renderData('Make', holding_proxy.self_registration?.brand)}
      {renderData('Model', productInfo?.model ?? '-')}
      {renderData(
        'Mileage',
        productInfo?.mileage &&
          productInfo?.mileage + '' + productInfo?.mileageUnit
      )}
      {renderData('VIN', productInfo?.chassisNo)}
      {renderData('Drive type', productInfo?.driveType)}
      {renderData(
        'Vehicle category',
        getVehicleCategoryByValue(productInfo?.vehicleCategory || '')?.label
      )}
      {renderData(
        'Commercial vehicle',
        productInfo?.isCommercial ? 'Yes' : 'No'
      )}
      {renderData('Store', appInfo.name)}
      {holding_proxy.self_registration?.srvInfo?.type &&
        renderData(
          'Product category',
          holding_proxy.self_registration?.srvInfo?.type
            ?.charAt(0)
            .toUpperCase() +
            holding_proxy.self_registration?.srvInfo?.type?.slice(1)
        )}
      {renderData(
        'Purchase date',
        holding_proxy.purchase_time && holding_proxy.purchase_time > 0
          ? getFormattedDate(holding_proxy.purchase_time)
          : '-'
      )}
      {renderData(
        'First registration date',
        productInfo?.regDate ? getFormattedDate(productInfo?.regDate) : '-'
      )}
      {renderData(
        'Year of manufacture',
        productInfo?.yearOfManufacture ? productInfo?.yearOfManufacture : '-'
      )}
      {/* {renderData(
        'Proof of purchase',
        renderPoP(setProofOfPurchaseModal, body?.private?.receipt)
      )} */}
      {renderData(
        'Last service date',
        productInfo?.lastServiceDate &&
          productInfo?.lastServiceDate !== '0000-00-00'
          ? productInfo?.lastServiceDate
          : '-'
      )}
      {renderData(
        'MOT date expiration',
        productInfo?.techCheckDate &&
          productInfo?.techCheckDate !== '0000-00-00'
          ? productInfo?.techCheckDate
          : '-'
      )}
      {holding_proxy.purchase_price &&
        renderData(
          'Purchase price',
          holding_proxy.purchase_price.amount !== 0 &&
            holding_proxy.purchase_price.currency !== '-'
            ? getCurrencyFormat(
                holding_proxy.purchase_price.amount,
                holding_proxy.purchase_price.currency
              )
            : '-'
        )}
      {renderData(
        'Registration Type',
        productInfo?.isManuallyRegistered ? t('Manual') : t('VRM')
      )}
    </>
  );
};

const renderData = (label: string, value: any) => {
  return (
    <div>
      <p className="mb-[10px] text-sm text-gray-500">{label}</p>
      <div className="break-words font-medium">{value ?? '-'}</div>
    </div>
  );
};

const renderPoP = (
  setProofOfPurchaseModal: React.Dispatch<React.SetStateAction<boolean>>,
  PoP?: UserUploadedDocument[]
) => {
  const totalFiles = PoP?.length ?? 0;
  return (
    <div
      className={
        'flex cursor-pointer items-center gap-2 ' +
        (totalFiles === 0 ? 'text-red-500' : 'text-black')
      }
      onClick={() => setProofOfPurchaseModal(true)}
    >
      {totalFiles} file(s) uploaded{' '}
      <div>
        <ArrowTopRightOnSquareIcon className="h-4 w-4 text-black" />
      </div>
    </div>
  );
};
