import { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useIdentity } from '@rabbit/data/portal';

import {
  UploadTask,
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
  deleteObject,
} from 'firebase/storage';
import {
  FBD_Holding_Public,
  FBD_Holding_Manufacturer,
  FBD_Holding_Private,
  UserUploadedDocument,
  isValidPersonaId,
  PersonaTypeSingleLetter,
  DTHolding_Public,
  DTHolding_Manufacturer,
  DTHolding_Private,
} from '@rabbit/data/types';
import { firebaseStorage } from '@rabbit/firebase/adapter-react';
import {
  UploadQueueStateReducer,
  addNewUpload,
  clearAttachedUploads,
  clearCompletedUploads,
  handleUploadComplete,
  setUploadError,
  setUploadProgress,
} from './reducer';
import {
  UploadedFileCategories,
  DocTypeShape,
  UploadQueueStateShape,
  PersonaTypeShape,
  CompletedUploadShape,
  DeleteFilePropsShape,
} from '@rabbit/elements/shared-types';
import {
  buildStoragePath,
  checkCorrectInputForDeleteFile,
  compareDocTypeWithPersona,
  fetchAllHoldingDocs,
  getCompletedUploadsOfCategory,
} from './helpers';
import { useLocation } from 'react-router-dom';
import { CaseFlow_Utils_RegisterFileDeletion } from '../../caseflow';

// TODO: Write up mini overview of how this all works - DC

/** Notes:
 *
 * - The FileStorageContext is used to upload files to Firebase storage and manage the state of the uploads
 * - The context provides a number of functions to upload files, delete files, and update holding documents with the uploaded files
 * - The context also provides a state object that contains the current state of the uploads
 * - The context is used in the FileStorageProviderWrapper component to provide the context to the rest of the application
 *
 * - Sage and Olive both have their own components which call the FileStorageContext to upload files and manage the state of the uploads,
 *  as well as rendering the upload form and managing its state. Auto handling of post-upload actions is also done in these components,
 *  or in SageFileUploadAutoUpdater and OliveFileUploadAutoUpdater, more specifically.
 */
/* --------------------- Context creation and interfaces -------------------- */

interface FileStorageContextShape {
  uploadFiles: (
    filesToUpload: File[],
    personaId: string,
    fileCategory: UploadedFileCategories,
    docType?: DocTypeShape
  ) => Promise<void>;
  uploadQueueState: UploadQueueStateShape;
  isUpdating: boolean;
  deleteFile: (urlOrPath: string, props: DeleteFilePropsShape) => Promise<void>;
  shouldRefetch: boolean;
  setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  moveCompletedUploadsToAttached: (
    completedUploads: CompletedUploadShape[]
  ) => void;
  clearAttached: {
    (AU: CompletedUploadShape[]): void;
  };
  updateHoldingWithFiles: (
    holdingId: string,
    CUFiles: UserUploadedDocument[],
    fileCategory: UploadedFileCategories
  ) => Promise<void>;
  unusedCompletedCleanup: () => void;
  getUploadsInQueue: (
    state: 'completed' | 'attached',
    category: UploadedFileCategories
  ) => CompletedUploadShape[];
}

const FileStorageContext = createContext<FileStorageContextShape | null>(null);

type FileStorageProviderWrapperProps = {
  children: React.ReactNode;
};

/* -------------------------------------------------------------------------- */
/*                              Provider Wrapper                              */
/* -------------------------------------------------------------------------- */

const FileStorageProviderWrapper = ({
  children,
}: FileStorageProviderWrapperProps) => {
  const identity = useIdentity();
  const location = useLocation();
  // To avoid nasty nasty bugginess, we'll always prevent the user from uploading files if they're already uploading something with this flag
  const [isUpdating, setIsUpdating] = useState(false);
  // Tells components to refetch their current files
  const [shouldRefetch, setShouldRefetch] = useState(false);

  /* --------------------------------- Reducer -------------------------------- */
  const initialUQState: UploadQueueStateShape = {
    ongoing: [],
    completed: [],
    attached: [],
  };

  const [uploadQueueState, dispatch] = useReducer(
    UploadQueueStateReducer,
    initialUQState
  );

  /* -------------------------------------------------------------------------- */
  /*                                File uploader                               */
  /* -------------------------------------------------------------------------- */

  // todo: separate docType? Yes, so that stuff like having to use holdingId instead of caseId for case uploads is clearer. Plus, we can already infer the doctype from the category, so it's not really needed - dc
  /**
   *
   * @param filesToUpload
   * @param personaId The persona ID to be associated with the file. This is not necessarily the one using the uploader - for instance, if used on Sage for uploading a shipping label, this would be the persona ID of the consumer associated with the case
   * @param fileCategory The category of the file. Please use the UploadedFileCategories enum
   * @param docType Made up of a type and a docid. For type === 'case', please use the holdingId as the docId rather than caseId
   */
  const uploadFiles = async (
    filesToUpload: File[],
    personaId: string,
    fileCategory: UploadedFileCategories,
    docType?: DocTypeShape
  ) => {
    // First let's check if we have everything we need
    if (!identity.uid) throw new Error('A valid identity is required');
    if (filesToUpload.length === 0) throw new Error('No files to upload');
    if (!isValidPersonaId(personaId)) throw new Error('Invalid persona ID');

    // TODO: should be only enums here, and types that we already use elsewhere - dc
    const personaType: PersonaTypeShape =
      personaId[0] === PersonaTypeSingleLetter.Consumer
        ? 'Consumer'
        : personaId[0] === PersonaTypeSingleLetter.Manufacturer
        ? 'Manufacturer'
        : personaId[0] === PersonaTypeSingleLetter.Repairer
        ? 'Repairer'
        : null;

    if (docType && personaType) compareDocTypeWithPersona(docType, personaType);

    // Now we setup the upload tasks
    const promises: UploadTask[] = [];

    filesToUpload.map((file) => {
      const storagePath = buildStoragePath(
        personaType,
        identity.uid,
        fileCategory,
        docType
      );

      if (!storagePath) throw new Error('Could not build storage path');

      const storageRef = ref(firebaseStorage, storagePath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      promises.push(uploadTask);

      addNewUpload(dispatch, file, storagePath, fileCategory);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(dispatch, storagePath, prog);
        },
        (err) => {
          setUploadError(dispatch, storagePath, err.message);
        },
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          const metadata = await getMetadata(uploadTask.snapshot.ref);
          const newFile: UserUploadedDocument = {
            ogFilename: file.name,
            url,
            metadata,
            version: 1,
          };

          if (file.type.includes('image')) {
            const img = new Image();
            img.src = url;
            img.onload = () => {
              const dimensions = {
                width: img.width,
                height: img.height,
              };
              newFile.dimensions = dimensions;
            };
          }
          // Clean undefined keys in metadata so FB doesn't complain about it later
          Object.keys(newFile.metadata).forEach(
            (key) =>
              newFile.metadata[key as keyof typeof newFile.metadata] ===
                undefined &&
              delete newFile.metadata[key as keyof typeof newFile.metadata]
          );

          handleUploadComplete(dispatch, storagePath, newFile, docType);
        }
      );
    });
    setIsUpdating(true);
    try {
      await Promise.all(promises);
      console.log('All files uploaded');
    } catch (err) {
      throw new Error(`Something went wrong while uploading the files: ${err}`);
    } finally {
      setIsUpdating(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                 Getting uploads in a given state / category                */
  /* -------------------------------------------------------------------------- */

  function getUploadsInQueue(
    state: 'completed' | 'attached',
    category: UploadedFileCategories
  ) {
    if (state === 'completed') {
      return getCompletedUploadsOfCategory(
        uploadQueueState.completed,
        category
      );
    }
    if (state === 'attached') {
      return getCompletedUploadsOfCategory(uploadQueueState.attached, category);
    }

    return [];
  }

  /* -------------------------------------------------------------------------- */
  /*                 Updating documents with the uploaded files                 */
  /* -------------------------------------------------------------------------- */

  /** Updates a holding document with the provided uploaded files.  */
  const updateHoldingWithFiles = async (
    holdingId: string,
    CUFiles: UserUploadedDocument[],
    fileCategory: UploadedFileCategories
  ) => {
    if (!identity.uid) throw new Error('A valid identity is required');
    if (!CUFiles) throw new Error('No files available for the update');
    const { holding, holding_manufacturer, holding_private } =
      await fetchAllHoldingDocs(holdingId);

    if (!holding || !holding_manufacturer || !holding_private)
      throw new Error('Unable to fetch holding documents');

    setIsUpdating(true);

    try {
      switch (fileCategory) {
        case UploadedFileCategories.ConsumerProofPurchase: {
          const receipts = holding_private?.receipt || [];
          const purchaseProofs = holding_manufacturer?.purchase_proof || [];

          holding_private.receipt = receipts.concat(CUFiles);
          holding_manufacturer.purchase_proof = purchaseProofs.concat(CUFiles);

          //We also update the public holding so its tupdate value stays in sync with the others
          await Promise.all([
            FBD_Holding_Public.set(holding),
            FBD_Holding_Private.set(holding_private),
            FBD_Holding_Manufacturer.set(holding_manufacturer),
          ]);
          break;
        }
        case UploadedFileCategories.SerialNumberProof: {
          const serialProofs = holding_private?.serial_proof || [];
          holding_private.serial_proof = serialProofs.concat(CUFiles);
          holding_manufacturer.serial_proof = serialProofs.concat(CUFiles);
          //We also update the public holding so its tupdate value stays in sync with the others
          await Promise.all([
            FBD_Holding_Public.set(holding),
            FBD_Holding_Private.set(holding_private),
            FBD_Holding_Manufacturer.set(holding_manufacturer),
          ]);
          break;
        }
        default:
          throw new Error('Invalid file category');
      }

      console.log('Updated holding successfully');
    } catch (err) {
      throw new Error(
        `Something went wrong while updating the holding: ${err}`
      );
    } finally {
      setIsUpdating(false);
    }
  };

  // TODO: should this do the updating immediately or wait until confirmation?

  /** Updates the document for a self registered vendable holding (SRV) with the uploaded files. Uses the uploadedTempFiles state if called immediately
   * after an upload, but can also be triggered at a later date by providing an optional filesArr */
  const updateSRVHoldingWithFiles = async (
    holdingId: string,
    CUFiles: UserUploadedDocument[]
  ) => {
    if (!identity.uid) throw new Error('A valid identity is required');
    if (!CUFiles) throw new Error('No files available for the update');

    const { holding, holding_manufacturer, holding_private } =
      await fetchAllHoldingDocs(holdingId);

    if (!holding || !holding_manufacturer || !holding_private)
      throw new Error('Unable to fetch holding documents');

    setIsUpdating(true);

    try {
      // Until a proper image management system is implemented, we will only support one image for SRV holdings. Uncomment the code below when that is the case - dc
      // const srv_holding_img = holding?.self_registration?.img || [];
      // const srv_holding_images = holding?.self_registration?.images || [];
      // const new_holding_img_urls = filesToAdd.map((item) => item.url);
      // srv_holding_img.push(...new_holding_img_urls);
      // srv_holding_images.push(...filesToAdd);

      const srv_holding_img = CUFiles[0]?.url ? [CUFiles[0]?.url] : [];
      const srv_holding_images = CUFiles[0] ? [CUFiles[0]] : [];

      if (holding.self_registration) {
        holding.self_registration.img = srv_holding_img ?? [];
        holding.self_registration.images = srv_holding_images ?? [];
      }

      //We also update the other holdings so their tupdate value stays in sync with the others
      await Promise.all([
        FBD_Holding_Public.set(holding),
        FBD_Holding_Private.set(holding_private),
        FBD_Holding_Manufacturer.set(holding_manufacturer),
      ]);

      // TODO: should this do the updating immediately or wait until confirmation?
      // setUploadedTempFiles(null);
      console.log('Updated holding successfully');
    } catch (err) {
      throw new Error(
        `Something went wrong while updating the holding: ${err}`
      );
    } finally {
      setIsUpdating(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                File deletion                               */
  /* -------------------------------------------------------------------------- */

  /** Takes in a fullPath or url and deletes a file from Firebase storage */
  const deleteFile = async (urlOrPath: string, props: DeleteFilePropsShape) => {
    //TODO: might need more ids here for other cases
    if (!identity.uid) throw new Error('A valid identity is required');

    const { category, holdingId, currentFiles, alterCaseFacts } = props;

    // Check if we have the required props for each specific category, throw errors if we don't
    checkCorrectInputForDeleteFile(props);

    setIsUpdating(true);

    // Check if the file is in state too
    const { completed, attached } = uploadQueueState;

    const completedFile = completed.find(
      (item) => item.uploadedFile.url === urlOrPath || item.key === urlOrPath
    );
    const attachedFile = attached.find(
      (item) => item.uploadedFile.url === urlOrPath || item.key === urlOrPath
    );

    // Remove deleted file from state
    if (completedFile) {
      clearCompletedUploads(dispatch, [completedFile.key]);
      // if it's just a completed file, we can just delete it and return
      try {
        const fileRef = ref(firebaseStorage, urlOrPath);
        await deleteObject(fileRef);
        console.log('Deleted file succesfully!');
        setIsUpdating(false);
        return;
      } catch (err) {
        throw new Error(`Something went wrong while deleting the file: ${err}`);
      }
    } else if (attachedFile) {
      clearAttachedUploads(dispatch, [attachedFile.key]);
    }

    // Clear file from documents it was attached to before deleting
    try {
      if (currentFiles && holdingId) {
        /* ------------------------ Within a caseflow context ----------------------- */
        if (alterCaseFacts) {
          await CaseFlow_Utils_RegisterFileDeletion(
            category,
            urlOrPath,
            alterCaseFacts,
            currentFiles,
            holdingId,
            removeFileFromHolding
          );
        } else {
          /* ---------------------------- Outside caseflow ---------------------------- */
          switch (category) {
            case UploadedFileCategories.ConsumerProofPurchase: {
              if (holdingId && removeFileFromHolding)
                await removeFileFromHolding(
                  holdingId,
                  UploadedFileCategories.ConsumerProofPurchase,
                  urlOrPath
                );
              break;
            }
            case UploadedFileCategories.SerialNumberProof: {
              if (holdingId && removeFileFromHolding)
                await removeFileFromHolding(
                  holdingId,
                  UploadedFileCategories.SerialNumberProof,
                  urlOrPath
                );
              break;
            }
          }
        }
      }

      const fileRef = ref(firebaseStorage, urlOrPath);
      await deleteObject(fileRef);
      console.log('Deleted file succesfully!');
    } catch (err) {
      throw new Error(`Something went wrong while deleting the file: ${err}`);
    } finally {
      setShouldRefetch(true);
      setIsUpdating(false);
    }
  };

  const removeFileFromHolding = async (
    holdingId: string,
    category: UploadedFileCategories,
    filePath: string
  ) => {
    if (!identity.uid) throw new Error('A valid identity is required');
    if (!holdingId) throw new Error('A valid holding ID is required');

    let holding: DTHolding_Public | null = null;
    let holding_manufacturer: DTHolding_Manufacturer | null = null;
    let holding_private: DTHolding_Private | null = null;

    ({ holding, holding_manufacturer, holding_private } =
      await fetchAllHoldingDocs(holdingId));

    if (!holding || !holding_manufacturer || !holding_private)
      throw new Error('Unable to fetch holding documents');

    setIsUpdating(true);

    // todo extract this to a helper function - dc
    switch (category) {
      case UploadedFileCategories.ConsumerProofPurchase: {
        const receipts = holding_private?.receipt || [];
        const purchaseProofs = holding_manufacturer?.purchase_proof || [];

        if (
          !receipts.some((item) => item.metadata.fullPath === filePath) &&
          !purchaseProofs.some((item) => item.metadata.fullPath === filePath)
        )
          throw new Error('File not found in the holding');

        const updatedReceipts = receipts.filter(
          (item) => item.metadata.fullPath !== filePath
        );

        const updatedPurchaseProofs = purchaseProofs.filter(
          (item) => item.metadata.fullPath !== filePath
        );

        holding_private.receipt = updatedReceipts;
        holding_manufacturer.purchase_proof = updatedPurchaseProofs;

        break;
      }
      case UploadedFileCategories.SerialNumberProof: {
        const serialProofsPrivate = holding_private?.serial_proof || [];
        const serialProofsManufacturer =
          holding_manufacturer?.serial_proof || [];

        if (
          !serialProofsPrivate.some(
            (item) => item.metadata.fullPath === filePath
          ) &&
          !serialProofsManufacturer.some(
            (item) => item.metadata.fullPath === filePath
          )
        )
          throw new Error('File not found in the holding');

        const updatedSerialProofsPrivate = serialProofsPrivate.filter(
          (item) => item.metadata.fullPath !== filePath
        );

        const updatedSerialProofsManufacturer = serialProofsManufacturer.filter(
          (item) => item.metadata.fullPath !== filePath
        );

        holding_private.serial_proof = updatedSerialProofsPrivate;
        holding_manufacturer.serial_proof = updatedSerialProofsManufacturer;

        break;
      }
      default:
        throw new Error('Invalid file category');
    }

    try {
      //We also update the public holding so its tupdate value stays in sync with the others
      await Promise.all([
        FBD_Holding_Public.set(holding),
        FBD_Holding_Private.set(holding_private),
        FBD_Holding_Manufacturer.set(holding_manufacturer),
      ]);
      console.log(
        `Cleared holding's file with category: ${category} successfully!`
      );
    } catch (err) {
      console.log('err', err);
      throw new Error(`Something went wrong while clearing the file: ${err}`);
    } finally {
      setIsUpdating(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                         General use state movement                         */
  /* -------------------------------------------------------------------------- */

  /** Moves completed uploads to the attached state */
  const moveCompletedUploadsToAttached = (CU: CompletedUploadShape[]) => {
    dispatch({
      type: 'ADD_ATTACHED_UPLOADS',
      payload: {
        uploads: CU,
      },
    });
    dispatch({
      type: 'CLEAR_COMPLETED_UPLOADS',
      payload: {
        keys: CU.map((item) => item.key),
      },
    });
  };

  const clearAttached = (AU: CompletedUploadShape[]) => {
    dispatch({
      type: 'CLEAR_ATTACHED_UPLOADS',
      payload: {
        keys: AU.map((item) => item.key),
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                             Unused file cleanup                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    // If user leaves the page, clear attached files
    if (uploadQueueState.attached.length !== 0) {
      clearAttachedUploads(
        dispatch,
        uploadQueueState.attached.map((item) => item.key)
      );
    }
  }, [location?.pathname]);

  // If user leaves the page or refreshes, delete completed unused files
  const unusedCompletedCleanup = async () => {
    if (uploadQueueState.completed.length !== 0) {
      uploadQueueState.completed.forEach(async (item) => {
        await deleteFile(item.key, {
          category: item.category,
          unattached: true,
        });
      });
    }
  };

  useEffect(() => {
    void unusedCompletedCleanup();
  }, [location?.pathname]);

  // This doesn't work - need to find a way to ensure unused files are deleted if user refreshes the page - dc
  // useEffect(() => {
  //   window.addEventListener(
  //     'beforeunload',
  //     () => void unusedCompletedCleanup()
  //   );

  //   return () =>
  //     window.removeEventListener(
  //       'beforeunload',
  //       () => void unusedCompletedCleanup()
  //     );
  // }, []);

  useEffect(() => {
    // If we're refetching, we need to clear the attached files state to avoid duplication on the FE
    if (shouldRefetch) {
      clearAttachedUploads(
        dispatch,
        uploadQueueState.attached.map((item) => item.key)
      );
    }
  }, [shouldRefetch]);

  // useEffect(() => {
  //   console.log(uploadQueueState);
  // }, [uploadQueueState]);
  /* -------------------------------------------------------------------------- */
  /*                               Context return                               */
  /* -------------------------------------------------------------------------- */
  const contextValues = useMemo(
    () => ({
      uploadFiles,
      isUpdating,
      uploadQueueState,
      deleteFile,
      shouldRefetch,
      setShouldRefetch,
      moveCompletedUploadsToAttached,
      clearAttached,
      updateHoldingWithFiles,
      unusedCompletedCleanup,
      getUploadsInQueue,
    }),
    [
      uploadFiles,
      uploadQueueState,
      isUpdating,
      deleteFile,
      shouldRefetch,
      setShouldRefetch,
      moveCompletedUploadsToAttached,
      clearAttached,
      updateHoldingWithFiles,
      unusedCompletedCleanup,
      getUploadsInQueue,
    ]
  );
  return (
    <FileStorageContext.Provider value={contextValues}>
      {children}
    </FileStorageContext.Provider>
  );
};

export { FileStorageContext, FileStorageProviderWrapper };
