import { UserUploadedDocument } from '@rabbit/data/types';
import { AppInfoShape } from '@rabbit/data/types';
import {
  Heading,
  Input,
  LoadingSpinner,
  UploadFilesForm,
} from '@rabbit/elements/shared-components';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { LogForms } from '../ClaimCostModal';
import { useFileStorage } from '@rabbit/bizproc/react';
import { CaseflowInterface } from '@rabbit/sage/context/CaseflowContext';
import { useState } from 'react';
import { CFCF_ShippingCostLog } from '@rabbit/bizproc/core';
import { t } from 'i18next';
import { UploadedFileCategories } from '@rabbit/elements/shared-types';

const ShippingForm = (
  appInfo: AppInfoShape,
  {
    caseFacts,
    caseActors,
    caseFlowCase,
    alterCaseFacts,
    alterCasePublicEmail,
    moveSpotlight,
    executeAction,
    caseId,
    operatingPersona,
    setShowModal,
  }: CaseflowInterface,
  personaId: string,
  option?: { editMode: boolean; index: number }
): LogForms<CFCF_ShippingCostLog> => ({
  name: 'shipping',
  schema: Yup.object().shape({
    description: Yup.string()
      .required(t(t('Please enter description')))
      .trim(),
    carrier: Yup.string().required(t('Please enter carrier')).trim(),
    date: Yup.string().trim(),
    tracking_number: Yup.string()
      .required(t('Please enter tracking number'))
      .trim(),
    amount: Yup.object({
      amount: Yup.string().required(t('Please enter amount')).trim(),
      currency: Yup.string().required(),
    }),
    VAT: Yup.string().required(t('Please enter VAT')).trim(),
    documents: Yup.array(Yup.mixed()),
  }),
  initialValues: {
    amount: {
      currency: appInfo.currency,
    },
  },
  onDelete: async (index, onComplete) => {
    const updatedRegistry = caseFacts?.shipping_cost_data || [];
    updatedRegistry.splice(index, 1);
    try {
      await alterCaseFacts({ shipping_cost_data: [...updatedRegistry] });
      toast.success(t('Record deleted successfully.'));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong, please try again'));
    } finally {
      onComplete();
    }
  },
  onSubmit: async (values, onComplete) => {
    const registry = caseFacts?.shipping_cost_data || [];
    const record = values;
    record.date = new Date(values.date || 0).getTime();
    if (option?.editMode) {
      registry[option.index] = record;
    } else {
      registry.push(record);
    }

    try {
      await alterCaseFacts({
        shipping_cost_data: [...registry],
        ...(values.internal_comment && {
          internal_comment: {
            comment: values.internal_comment,
            author: personaId,
          },
        }),
      });
      toast.success(t('Claim updated successfully.'));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong, please try again'));
    } finally {
      onComplete();
    }
  },
  Form: ({ setFieldValue, values }) => {
    const {
      uploadFiles,
      uploadTaskCat,
      isUpdating,
      uploadProgress,
      deleteFile,
      deleteUnusedTempFiles,
      updateHoldingWithFiles,
      uploadedTempFiles,
      updateVendableWithFiles,
      clearFileFromHolding,
      clearFileFromVendable,
      clearFileFromState,
      clearAllTempFilesFromState,
    } = useFileStorage();

    const [uploadedProofOfPostage, setUploadedProofOfPostage] = useState<
      UserUploadedDocument[]
    >(values?.documents);
    if (
      !setShowModal ||
      !executeAction ||
      !alterCaseFacts ||
      !alterCasePublicEmail ||
      !moveSpotlight ||
      !operatingPersona ||
      !caseFacts ||
      !caseId ||
      !caseFlowCase
    )
      return <LoadingSpinner size={'xs'} />;

    const onProofOfPostageUpdated = async (
      files: UserUploadedDocument[],
      _added = true
    ) => {
      if (files && files.length && caseFlowCase) {
        clearAllTempFilesFromState();
        setUploadedProofOfPostage(files);
        await setFieldValue('documents', files);
      }
    };
    return (
      <>
        <Input
          type="text"
          label={t('Description') + '*'}
          name="description"
          settings={{
            placeholder: t('Enter description here'),
          }}
        />
        <Input
          type="text"
          label={t('Carrier' + '*')}
          name="carrier"
          settings={{
            id: 'carrier',
            placeholder: t('Enter the carrier name'),
          }}
        />
        <div className="grid grid-cols-2 gap-4">
          <Input
            type="datepicker"
            label={t('Date')}
            name="date"
            settings={{
              id: 'date',
              placeholder: 'DD/MM/YYYY',
            }}
          />
          <Input
            type="text"
            label={t('Tracking number' + '*')}
            name="tracking_number"
            settings={{
              id: 'tracking_number',
              placeholder: t('Enter the tracking number'),
            }}
          />
          <Input
            type="currency"
            label={t('Amount') + '*'}
            name="amount"
            settings={{
              disableCurrency: true,
              placeholder: t('Amount'),
            }}
          />

          <Input
            type="text"
            label={t('VAT') + '*'}
            name="VAT"
            settings={{
              id: 'vat',
              placeholder: t('VAT'),
              append: '%',
            }}
          />
        </div>
        <UploadFilesForm
          label={t('Supporting documents')}
          type="case"
          docId={caseId ?? ''}
          personaId={caseActors?.consumer ?? ''}
          currentFiles={uploadedProofOfPostage ?? []}
          onFilesUploaded={onProofOfPostageUpdated}
          uploadHandlers={{
            uploadedTempFiles,
            category: UploadedFileCategories.PostageReceipts,
            uploadFiles,
            uploadTaskCat,
            isUpdating,
            uploadProgress,
            deleteFile,
            updateHoldingWithFiles,
            updateVendableWithFiles,
            clearFileFromHolding,
            clearFileFromVendable,
            clearFileFromState,
            deleteUnusedTempFiles,
          }}
        />
        <Heading kind="p" className="text-gray-500">
          *{t('required')}
        </Heading>
      </>
    );
  },
});
export default ShippingForm;
