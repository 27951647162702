import { Modal, TabsGeneric } from '@rabbit/elements/shared-components';
import { useContext, useState } from 'react';
import ModalAddEditItem from '../components/organisms/ModalNewEditProduct/ModalAddEditItem';
//import FaultsCategories from '../components/templates/ProductsTabElements/FaultsCategoriesTabs';
import FaultsTab from '../components/templates/ProductsTabElements/FaultsTab';
import CustomersTab from '../components/templates/ProductsTabElements/CustomersTab';
import PartnersTab from '../components/templates/ProductsTabElements/PartnersTab';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../context/UserContext';
import { getUserPermissions } from '../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { Permissions } from '@rabbit/data/types';
import ProductsTab from '../components/templates/ProductsTabElements/ProductsTab';
import { VendableEditorProviderWrapper } from '@rabbit/bizproc/react';
import IssueTypesTab from '../components/templates/ProductsTabElements/IssueTypesTab';

export function ManageView() {
  const { t } = useTranslation();
  const contextValues = useContext(UserContext);
  const navigate = useNavigate();
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, contextValues);

  const [isNewEditModalOpen, setIsNewEditModalOpen] = useState(false);
  const [itemDetails, setItemDetails] = useState<any>();
  const [modalTitle, setModalTitle] = useState<string>();
  const [modalKind, setModalKind] = useState<
    'products' | 'parts' | 'faults' | 'faults categories'
  >();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleEditModal = (
    kind: 'products' | 'parts' | 'faults' | 'faults categories',
    title: string,
    itemDetails?: any
  ) => {
    setModalKind(kind);
    setModalTitle(title);
    setIsNewEditModalOpen(true);
    setItemDetails(itemDetails);
  };

  const modalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to delete this product. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () => console.log('delete --> ToDo'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  const tabs = [
    {
      label: 'Customers',
      children: <CustomersTab />,
    },
    // {
    //   label: 'Products',
    //   children: <ProductsTab handleEditModal={handleEditModal} />,
    // },
    ...(t('CFG_MANAGE_PARTNERS') === 'true'
      ? [
          {
            label: 'Partners',
            children: <PartnersTab />,
          },
        ]
      : []),
    // {
    //   label: 'Retailers',
    //   children: <CustomersTab />
    // },
    {
      label: 'Faults',
      children: <FaultsTab />,
    },
    // {
    //   label: 'Fault categories',
    //   children: <FaultsCategories handleEditModal={handleEditModal} />,
    // },
    {
      label: 'Issue types',
      children: <IssueTypesTab />,
    },
  ];

  //Check if user has permissions to view this page
  if (!userPermissions?.includes(Permissions.Owner)) navigate('/');

  return (
    <VendableEditorProviderWrapper>
      <div className="pt-5 pb-4">
        <TabsGeneric items={tabs} />
      </div>
      {isNewEditModalOpen && (
        <div>
          <ModalAddEditItem
            handleClose={() => setIsNewEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            handleSubmit={() => void 0}
            itemDetails={itemDetails}
            kind={modalKind}
            title={modalTitle}
          />
        </div>
      )}
      {isConfirmationModalOpen && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        />
      )}
    </VendableEditorProviderWrapper>
  );
}

export default ManageView;
